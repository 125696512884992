<template>
    <v-navigation-drawer
        v-model="isDrawerShownModel"
        :permanent="$vuetify.breakpoint.mdAndUp"
        class="navigation-drawer"
        hide-overlay
    >
        <div class="navigation-drawer__header">
            <div class="pl-2">
                <v-avatar class="avatar" :width="96" tile @click="redirectToLivePage">
                    <v-img src="@/assets/images/astro-logo.png" contain />
                </v-avatar>
            </div>
            <v-btn v-if="isDrawerShown && isMobile" icon @click="isDrawerShownModel = false">
                <v-icon class="icon">close</v-icon>
            </v-btn>
        </div>
        <v-list class="navigation-drawer__content">
            <v-list-item :ripple="false" link :to="{ name: 'live' }">
                <v-list-item-icon>
                    <v-icon class="icon">online_prediction</v-icon>
                </v-list-item-icon>
                <v-list-item-content data-intercom-target="live">
                    {{ $t('menu.live') }}
                </v-list-item-content>
            </v-list-item>

            <v-list-group
                v-if="isReportsViewer"
                :class="{ 'list-group--active': isReportsTab }"
                :value="isReportsTab"
                :ripple="false"
            >
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon class="icon">bar_chart</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content data-intercom-target="reports">
                        {{ $t('menu.reports') }}
                    </v-list-item-content>
                </template>

                <v-list-item v-if="isPerformanceViewer" :ripple="false" link :to="{ name: 'performances' }">
                    <v-list-item-content data-intercom-target="performance">
                        {{ $t('reportings.performancesTab.label') }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isFinanceViewer" :ripple="false" link :to="{ name: 'finance' }">
                    <v-list-item-content data-intercom-target="finance">
                        {{ $t('reportings.financeTab.label') }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isQualityViewer" :ripple="false" link @click="navigateToUrl('/r/reports/quality')">
                    <v-list-item-content data-intercom-target="quality">
                        {{ $t('reportings.qualityTab.label') }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isFinanceViewer" :ripple="false" link @click="navigateToUrl('/r/reports/sales')">
                    <v-list-item-content data-intercom-target="items_sold">
                        {{ $t('reportings.salesTab.label') }}
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isRefundsViewer" :ripple="false" link @click="navigateToUrl('/r/reports/refunds')">
                    <v-list-item-content data-intercom-target="refunds">
                        {{ $t('reportings.refundsTab.label') }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="isOrdersViewer"
                    :ripple="false"
                    link
                    @click="navigateToUrl('/r/reports/orders-details')"
                >
                    <v-list-item-content data-intercom-target="orders_details">
                        {{ $t('reportings.ordersDetailsTab.label') }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isHygieneViewer" :ripple="false" link @click="navigateToUrl('/r/reports/hygiene')">
                    <v-list-item-content data-intercom-target="hygiene">
                        {{ $t('reportings.hygieneTab.label') }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="isDeliveriesPerformance"
                    :ripple="false"
                    link
                    @click="navigateToUrl('/r/reports/delivery-control')"
                >
                    <v-list-item-content data-intercom-target="delivery-control">
                        {{ $t('reportings.deliveryControlTab.label') }}
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <v-list-item
                v-if="isInvoicesViewer || hasInvoicesBeta"
                :ripple="false"
                :value="isInvoicesTab"
                :class="{ 'v-list-item--active': isInvoicesTab }"
                link
                @click="handleInvoicesRedirection"
            >
                <v-list-item-icon>
                    <v-icon class="icon">description</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ $t('menu.invoices') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-group
                v-if="isMenuManagerViewer"
                :class="{ 'list-group--active': isMenuManagerTab }"
                :value="isMenuManagerTab"
                :ripple="false"
            >
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon class="icon">restaurant_menu</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{ $t('menu.menuManager') }}
                    </v-list-item-content>
                </template>

                <v-list-item
                    v-if="isMenuManagerTemplatesViewer"
                    link
                    :ripple="false"
                    :to="{ path: '/menu-manager/menus' }"
                >
                    <v-list-item-content>
                        {{ $t('menuManager.homepage.menus.title') }}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="isMenuManagerRestaurantsViewer"
                    link
                    :ripple="false"
                    :to="{ path: '/menu-manager/restaurants' }"
                >
                    <v-list-item-content>
                        {{ $t('menuManager.homepage.restaurants.title') }}
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="hasItemLibraryAccess" :ripple="false" link @click="handleItemLibraryRedirection">
                    <v-list-item-content>
                        {{ $t('menuManager.homepage.itemLibrary.title') }}
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-item v-if="isOpeningsViewer" :ripple="false" link :to="{ name: 'openingHours' }">
                <v-list-item-icon>
                    <v-icon class="icon">schedule</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ $t('menu.openingHours') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hasKitchensAccess" :ripple="false" link @click="navigateToUrl('/r/kitchens')">
                <v-list-item-icon>
                    <v-icon class="icon">location_city</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ $t('menu.kitchens') }}
                </v-list-item-content>
            </v-list-item>
            <v-list-group
                v-if="hasSupplyAccess"
                :class="{ 'list-group--active': isSupplierTab }"
                :value="isSupplierTab"
                :ripple="false"
            >
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-badge color="primary" dot :value="!!totalOrderingReminder || !!totalDeliveriesReminder">
                            <v-icon class="icon">local_shipping</v-icon>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content data-intercom-target="supply">
                        {{ $t('supply') }}
                    </v-list-item-content>
                </template>

                <v-list-item v-if="hasSuppliersAccess" :ripple="false" link @click="navigateToUrl('/r/suppliers')">
                    <v-list-item-content>
                        <div class="sub-list-item">
                            <span>{{ $t('menu.orders') }}</span>
                            <span v-if="!!totalOrderingReminder" class="badge">
                                {{ totalOrderingReminder > 99 ? '99+' : totalOrderingReminder }}
                            </span>
                        </div>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="hasSupplyAccess" :ripple="false" link @click="navigateToUrl('/r/orders')">
                    <v-list-item-content>
                        <div class="sub-list-item">
                            <span>{{ $t('menu.deliveries') }}</span>
                            <span v-if="!!totalDeliveriesReminder" class="badge">
                                {{ totalDeliveriesReminder > 99 ? '99+' : totalDeliveriesReminder }}
                            </span>
                        </div>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="hasSupplyMailbox" :ripple="false" link @click="navigateToUrl('/r/mailbox')">
                    <v-list-item-content>
                        {{ $t('mailbox') }}
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
        <div class="navigation-drawer__footer">
            <v-btn id="support" class="support-btn" :ripple="false" text block>
                <v-icon class="icon">contact_support</v-icon>
                <div>Support</div>
            </v-btn>
            <a :href="localizeDocumentationLink" target="_blank">
                <v-btn class="support-btn" :ripple="false" text @click="handleDocumentationClick()">
                    <v-icon class="icon auto_stories" />
                    <div>{{ $t('shared.seeDocumentation') }}</div>
                </v-btn>
            </a>
            <div class="user-info">
                <v-icon size="30">account_circle</v-icon>
                <p class="user-email">
                    {{ userEmail }}
                </p>
                <LogoutButton />
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { deliveriesApi, providersApi } from '@/apis/gatewaySupply';
import LogoutButton from '@/components/Utils/Buttons/LogoutButton';
import { groups, hasGroup } from '@/router/groups';
import { hasRole, roles } from '@/router/roles';
import { navigateToUrl } from 'single-spa';

export default {
    components: {
        LogoutButton,
    },
    props: {
        isDrawerShown: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            totalDeliveriesReminder: 0,
            totalOrderingReminder: 0,
        };
    },
    computed: {
        isDrawerShownModel: {
            get() {
                return this.isDrawerShown;
            },
            set(newValue) {
                this.$emit('update:isDrawerShown', newValue);
            },
        },
        userEmail() {
            return this.$auth.user.email;
        },
        isReportsTab() {
            return this.$route.matched.some((route) => route.path.includes('/reports'));
        },
        isMenuManagerTab() {
            return this.$route.matched.some((route) => route.path.includes('/menu-manager'));
        },
        isSupplierTab() {
            return this.$route.matched.some((route) => route.path.includes('/suppliers'));
        },

        hasItemLibraryAccess() {
            return hasRole(roles.ItemLibraryUser);
        },

        isInvoicesTab() {
            return this.$route.matched.some((route) => route.path.includes('/invoices'));
        },

        hasKitchensAccess() {
            return hasRole(roles.KitchensViewer);
        },

        hasSuppliersAccess() {
            return hasRole(roles.SupplyOrderEditor) || hasGroup(groups.NewOrderingProcess);
        },

        hasSupplyAccess() {
            return (
                hasGroup(groups.NewOrderingProcess) ||
                hasRole(roles.SupplyHistoryViewer) ||
                hasRole(roles.SupplyOrderEditor) ||
                hasRole(roles.SupplyReceptionController)
            );
        },

        hasSupplyMailbox() {
            return hasGroup(groups.SupplyMailbox);
        },

        hasInvoicesBeta() {
            return hasRole(roles.InvoicesBeta) || hasGroup(groups.InvoicesBetaTester) || hasGroup(groups.Accountant);
        },

        isMenuManagerViewer() {
            return (
                hasRole(roles.MenuManagerViewer) ||
                hasRole(roles.RestaurantLiveViewer) ||
                hasRole(roles.LocalizedMenuManagerUser)
            );
        },

        isInvoicesViewer() {
            return hasRole(roles.InvoicesViewer) || hasRole(roles.RestaurantRevenue);
        },

        isOpeningsViewer() {
            return hasRole(roles.OpeningsViewer) || hasRole(roles.RestaurantLiveViewer);
        },

        isPerformanceViewer() {
            return hasRole(roles.PerformanceViewer) || hasRole(roles.RestaurantPerformance);
        },

        isFinanceViewer() {
            return hasRole(roles.FinanceViewer) || hasRole(roles.RestaurantRevenue);
        },

        isQualityViewer() {
            return hasRole(roles.QualityViewer) || hasRole(roles.RestaurantPerformance);
        },

        isRefundsViewer() {
            return hasRole(roles.RefundsViewer) || hasRole(roles.RestaurantRevenue);
        },

        isOrdersViewer() {
            return hasRole(roles.OrdersViewer) || hasRole(roles.RestaurantRevenue);
        },

        isHygieneViewer() {
            return hasRole(roles.HygieneViewer) || hasRole(roles.RestaurantPerformance);
        },

        isDeliveriesPerformance() {
            return hasRole(roles.DeliveriesPerformance);
        },

        isMenuManagerTemplatesViewer() {
            return hasRole(roles.MenuManagerTemplatesViewer) || hasRole(roles.LocalizedMenuManagerUser);
        },
        isMenuManagerRestaurantsViewer() {
            return (
                hasRole(roles.MenuManagerRestaurantsViewer) ||
                hasRole(roles.RestaurantLiveViewer) ||
                hasRole(roles.LocalizedMenuManagerUser)
            );
        },

        isReportsViewer() {
            return [
                roles.FinanceViewer,
                roles.HygieneViewer,
                roles.LeaderboardViewer,
                roles.OrdersViewer,
                roles.PerformanceViewer,
                roles.QualityViewer,
                roles.RefundsViewer,
                roles.DeliveriesPerformance,
                roles.RestaurantPerformance,
                roles.RestaurantRevenue,
            ].some((role) => hasRole(role));
        },

        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },

        localizeDocumentationLink() {
            return this.$dayjs().locale() === 'fr'
                ? 'https://taster.notion.site/Help-FR-92daf05bc67a4df69611b552721b29f8?pvs=4'
                : 'https://taster.notion.site/Help-EN-32388184ade04648b71284c18c0bd4ad?pvs=4';
        },
    },
    async created() {
        try {
            await Promise.all([this.fetchOrderingReminder(), this.fetchDeliveriesReminder()]);
        } catch (error) {
            this.$errorReporting.onError(error);
        }
    },
    methods: {
        async fetchOrderingReminder() {
            if (this.hasSuppliersAccess) {
                const providers = await providersApi.listProviders({ haveReminder: true, perPage: 1 });
                this.totalOrderingReminder = providers.total;
            }
        },

        async fetchDeliveriesReminder() {
            if (this.hasSupplyAccess) {
                const deliveries = await deliveriesApi.searchDeliveries({
                    searchDeliveriesParams: {
                        isReceptionChecked: false,
                        isReceptionEditable: true,
                        perPage: 1,
                        receptionDate: this.$dayjs().utc(true).toDate(),
                    },
                });
                this.totalDeliveriesReminder = deliveries.total;
            }
        },

        navigateToUrl,
        redirectToLivePage() {
            if (this.$route.name !== 'live') {
                this.$router.push({ name: 'live' });
            }
        },

        handleInvoicesRedirection() {
            if (this.hasInvoicesBeta) navigateToUrl('/r/invoices-beta');
            else this.$router.push('/invoices');
        },

        handleItemLibraryRedirection() {
            window.dataLayer.push({
                event: 'item_library-click',
            });

            navigateToUrl('/r/menu-manager/item-library');
        },

        handleDocumentationClick() {
            window.dataLayer.push({
                event: 'documentation-click',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$defaultTextColor: rgba(0, 0, 0, 0.87);
$activeTextColor: #5053f1;

.icon {
    font-size: 20px;
    color: inherit;
}

.sub-list-item {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 8px;
    justify-content: space-between;
}

.badge {
    align-items: center;
    background-color: var(--v-primary-base);
    border-radius: 32px;
    color: white;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 20px;
    min-width: 20px;
    padding-inline: 8px;
    text-align: center;
}

.navigation-drawer {
    word-break: break-word;
    max-height: 100vh;
    width: 305px !important;
    z-index: 10 !important;
    left: 0;
    position: fixed;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        z-index: 1;

        &.v-navigation-drawer {
            height: 100%;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
    }

    &__content {
        font-weight: 500;
        padding: 8px 12px;
        overflow-y: auto;
    }

    &__footer {
        padding: 16px;
        border-top: 1px solid lightgrey;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    ::v-deep {
        .v-navigation-drawer__content {
            display: grid;
            grid-template-rows: auto 1fr auto;
            height: 100vh;
            width: max-content;

            @media #{map-get($display-breakpoints, 'sm-and-down')} {
                justify-content: space-between;
            }
        }

        .v-list-group:not(:last-child) {
            margin-bottom: 8px;
        }

        .v-list-group__items > .v-list-item {
            padding-left: 56px;
            font-size: 14px;
            min-height: 36px;
            height: 36px;
        }

        .v-list-group__header {
            height: 48px;
            margin-bottom: 8px;
        }

        .v-list-item::before,
        .v-list-group__header::before {
            border-radius: 8px;
        }

        .v-list-item {
            &:hover {
                &::before {
                    background-color: $activeTextColor;
                    opacity: 0.04;
                }
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            .icon {
                color: grey;
            }
        }

        .v-list-item.v-list-item--active {
            border-radius: 8px;
            color: $activeTextColor;
            background-color: rgba($activeTextColor, 0.08);

            &::before {
                background-color: $activeTextColor;
                opacity: 0.08;
            }

            &:focus {
                &::before {
                    opacity: 0.12;
                }
            }

            .icon {
                color: inherit;
            }
        }

        .v-list-group__header__append-icon .v-icon {
            font-size: 12px;
        }

        .list-group--active > .v-list-group__header {
            color: $activeTextColor !important;
            border-radius: 8px;

            &::before {
                background-color: $activeTextColor;
                opacity: 0.08 !important;
            }

            .icon {
                color: inherit;
            }
        }

        .v-list-item__icon:first-child {
            margin-right: 16px;
        }
    }
}

.support-btn.v-btn {
    font-weight: 500;
    padding: 12px 16px;
    justify-content: flex-start;
    font-size: 16px;
    letter-spacing: normal;
    height: 48px;

    &:hover {
        &::before {
            background-color: $activeTextColor;
            opacity: 0.04;
        }
    }

    &:focus {
        &::before {
            background-color: $activeTextColor;
            opacity: 0.12;
        }
    }

    .icon {
        opacity: 0.56;
        margin-right: 16px;
    }
}

.nav-list {
    overflow-y: scroll;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
}

.user-email {
    font-size: 12px;
}
</style>
