import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: { iconfont: 'md' },
    theme: {
        options: { customProperties: true, variations: false },
        themes: {
            light: {
                'background-dark': '#F6F9FE',
                'border-chip': '#bdbdbd',
                'border-primary': '#88A6FF',
                'border-light': '#e7e9ff',
                'border-yellow': '#FDE5C0',
                'border-avatar': '#E9E9E9',
                'brand-light': '#7B7B7B',
                'chip-light': '#F5F5F5',
                'error-light': '#fea9a9',
                'error-lighter': '#FDEFED',
                'greenLeaderboard-light': '#32FB8D',
                'grey-light': '#757575',
                'neutral-light': '#F0F1F4',
                'primary-dark': '#0435C8',
                'primary-darker': '#020159',
                'primary-light': '#F6F9FE',
                'primary-lighter': '#FBFCFE',
                'redLeaderboard-light': '#FB32DB',
                'secondary-light': '#F3F4F6',
                'success-light': '#45B384',
                'success-lighter': '#EDFDF6',
                'warning-dark': '#D28304',
                'warning-darker': '#9B6103',
                'warning-main': '#ED6C02',
                'warning-light': '#F9CE8A',
                'warning-lighter': '#FFF7EB',
                border: '#CACFD8',
                dark: '#02021D',
                disabled: '#74767B',
                error: '#D6331A',
                critical: '#E50000',
                grey: '#74747B',
                info: '#7712B5',
                light: '#FBFCFE',
                primary: '#3264FB',
                secondary: '#74747B',
                success: '#108452',
                white: '#FFFFFF',
            },
        },
    },
});
